import React, { useEffect } from 'react';
import 'antd/dist/antd.css';
import ZajilEBooking from './components/pages/ZajilEBooking/ZajilEBooking';
import { ConfigProvider, message } from 'antd';
import { IntlProvider } from 'react-intl';
import translations from './translations/eBooking.json';
import zajilCityTranslations from './translations/cityList.json';
import { useDispatch, useSelector } from 'react-redux';
import ar_EG from 'antd/es/locale/ar_EG';
import en_US from 'antd/es/locale/en_US';
import { fetchOrganisationDetails } from "./api/zajilBooking";
import { setMasterdata } from './actions/fetchOrganisationDetails';
import { DEFAULT_PRETTY_NAME } from './globals';

function App() {
  const dispatch = useDispatch();
  const languageConfigMap = {
    ar: ar_EG,
    en: en_US
  }
  const [masterLoading, setMasterLoading] = React.useState(true);
  const language = useSelector((state:any) => state.translation.language);
  const languageConfig = languageConfigMap[language];
  useEffect(() => {
    getMasterData();
  }, []);

  const getMasterData = async () => {
    setMasterLoading(true);
    let origin = window.location.hostname;
    var organisationPrettyName = DEFAULT_PRETTY_NAME; //default organisation for localhost
    if (origin.indexOf(".") > 0) {
      organisationPrettyName = origin.substring(0, origin.indexOf("."));
    }
    const headers = {
      "organisation-pretty-name": organisationPrettyName,
      "organisation-url": origin,
    };
    const response = await fetchOrganisationDetails(headers);
    window.localStorage.setItem('bookingcode', response.data.retail_booking_code);
    if (response.isSuccess) {
      dispatch(setMasterdata(response.data));
    } else {
      message.error(response.errorMessage);
    }
    setMasterLoading(false);
  };
  return (

    <ConfigProvider locale={languageConfig} direction={language === 'ar' ? 'rtl' : 'ltr'}>
      <IntlProvider locale={language} messages={{ ...translations[language], ...zajilCityTranslations[language] }} >
        <div className="App">
          <ZajilEBooking masterLoading={masterLoading}/>
        </div>
      </IntlProvider>
    </ConfigProvider>
  );
}

export default App;
