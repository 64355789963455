import apiProvider from "./provider";
import { getAuthenticationHeaders, getErrorMessage } from "./utils";
import qs from "qs";

export const GET = async (
  url: string,
  params?: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      paramsSerializer(input: any) {
        return qs.stringify(input, { arrayFormat: 'repeat' });
      },
      headers: {
        ...headers,
      },
    });
    return {
      isSuccess: true,
      data: response?.data?.data || response?.data,
      status: response.status,
    };
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
      status: err && err.response && err.response.status,
    };
  }
};

export const POST = async (
  url: string,
  body: any,
  headers = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const meta = {
      headers,
    };
    const response = await apiProvider.post(url, body, meta);
    const dataToReturn = {
      isSuccess: true,
      data: response?.data?.data || response?.data,
      message: response?.data?.message,
    };
    return dataToReturn;
  } catch (err) {
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false,
    };
  }
};
